import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { NaooAnalyticsManager } from '../analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from '../analytics/analytics-event-info';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalizationService } from '../services/translation/localization.service';
import {
  FeatureFlag,
  Language,
} from 'src/app/core/services/session/models/session-record';
import { SessionFacade } from '../../core/store/session/session.facade';

interface LanguageType {
  display: string;
  lang: Language;
}

@Component({
  selector: 'naoo-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageMenuComponent implements OnInit, OnDestroy {
  @Input() isDisabled: boolean;
  isMenuOpen = false;

  languages: LanguageType[] = [
    {
      display: 'English',
      lang: Language.en,
    },
    {
      display: 'Français',
      lang: Language.fr,
    },
  ];
  selectedLanguage: Language;
  destroyed$ = new Subject();

  constructor(
    public localizationService: LocalizationService,
    private readonly analytics: NaooAnalyticsManager,
    private readonly sessionFacade: SessionFacade,
  ) {}

  ngOnInit() {
    this.sessionFacade
      .isFeatureEnabled(FeatureFlag.SPANISH_LANGUAGE)
      .pipe(first())
      .subscribe((includeSpanish: boolean) => {
        if (includeSpanish) {
          this.languages.push({
            display: 'Español',
            lang: Language.es,
          });
        }
      });
    this.selectedLanguage = this.localizationService.currentLanguage;
    this.sortLanguages();

    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((language) => {
        this.selectedLanguage = language;
        this.sortLanguages();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  sortLanguages() {
    if (this.selectedLanguage !== this.languages[0].lang) {
      this.languages.reverse();
    }
  }

  toggleLanguage(event: MatButtonToggleChange): void {
    this.setLanguage(event.value);
  }

  setLanguage(language: Language) {
    const eventInfo: AnalyticsEventInfo = {
      action: 'click',
      category: 'general',
      label: 'change_language',
    };
    this.analytics.trackAnalyticsEvent(eventInfo);
    this.localizationService.use(language);
    this.selectedLanguage = language;
    this.sortLanguages();
  }

  getSelectedLanguageDisplayName(): string {
    return this.languages.find(
      (language) => language.lang === this.selectedLanguage,
    )?.display;
  }
}
