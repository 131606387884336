import {
  combineLatest,
  Observable,
  of as observableOf,
  throwError as observableThrowError,
} from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ParseSpreadsheetService } from '../parse-spreadsheet/parse-spreadsheet.service';
import {
  importOrderImportableErrors,
  ImportOrderRow,
  ImportOrderRowErrors,
} from '../../models/import-order-row';
import { ImportOrderSanitizer } from './sanitizer/import-order-sanitizer';
import { MaterialValidationService } from '../material-validation/material-validation.service';
import { CustomerMaterialFacade } from '../../../core/store/customer-material/customer-material.facade';
import { MaterialAvailabilityFacade } from '../../../core/store/material-availability/material-availability.facade';
import {
  isAvailableByNonCase,
  isAvailableByUom,
  MaterialAvailability,
} from '../../models/material-availability';
import { NaooConstants } from '../../NaooConstants';

type ImportOrderData = Map<string, ImportOrderRow[]>;

export enum ValidationResultType {
  Empty,
  NoErrors,
  RowErrors,
  TooManyRows,
  TooFewColumns,
  InvalidFileFormat,
}

export class ValidationResultData {
  type: ValidationResultType;
  data: ImportOrderData;

  constructor(type: ValidationResultType = ValidationResultType.NoErrors) {
    this.type = type;
    this.data = new Map<string, ImportOrderRow[]>();
  }
}

export class ImportOrderValidationError implements Error {
  name: string;
  message: string;
  resultData: ValidationResultData;

  constructor(resultData: ValidationResultData) {
    this.resultData = resultData;
  }
}

export enum RowData {
  OfferingId = 0,
  CaseQuantity = 1,
  UnitQuantity = 2,
}

enum BreakCase {
  YES = 'Y',
  OUI = 'O',
  NO = 'N',
}

const validBreakCaseValues = Object.keys(BreakCase).map(
  (k) => BreakCase[k as keyof typeof BreakCase] as string,
);

@Injectable({ providedIn: 'root' })
export class ImportOrderValidationService {
  static readonly MAX_LINES = 999;
  static readonly MAX_QUANTITY = 999;

  private readonly materialNumberRegex = new RegExp('^[0-9]+$');

  constructor(
    private parseSpreadsheetService: ParseSpreadsheetService,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialValidationService: MaterialValidationService,
    private customerMaterialFacade: CustomerMaterialFacade,
  ) {}

  validateContent(file: Blob, cartContent: string) {
    if (file) {
      return this.validateFile(file);
    } else {
      return this.validateCartContent(cartContent);
    }
  }

  private validateFile(file: Blob): Observable<ValidationResultData> {
    return combineLatest([
      this.parseSpreadsheetService
        .parseFile(file, new ImportOrderSanitizer())
        .pipe(tap((fileData) => this.validateFileDataFormat(fileData))),
      this.getLoadedCustomerMaterial(),
    ]).pipe(
      map(([fileData, customerMaterial]) => {
        const rows = this.createRows(fileData, customerMaterial);
        this.validateRowDataFormat(rows);
        return rows;
      }),
      mergeMap((rows) => this.validateResults(rows)),
      map((importOrderData) => this.createValidationResult(importOrderData)),
      catchError((error) => {
        if (error instanceof ImportOrderValidationError) {
          return observableOf(error.resultData);
        }
        return observableThrowError(error);
      }),
    );
  }

  private validateCartContent(
    cartTransferData?: string,
  ): Observable<ValidationResultData> {
    return combineLatest([this.getLoadedCustomerMaterial()]).pipe(
      map(() => {
        const rows = this.splitCartTransferRows(cartTransferData);
        this.validateRowDataFormat(rows);
        return rows;
      }),
      mergeMap((rows) => this.validateResults(rows)),
      map((importOrderData) => {
        return this.createValidationResult(importOrderData);
      }),
      catchError((error) => {
        if (error instanceof ImportOrderValidationError) {
          return observableOf(error.resultData);
        }
        return observableThrowError(error);
      }),
    );
  }

  private validateResults(rows: any): Observable<any> {
    return this.validateMaterialAccessibility(rows).pipe(
      map((rows) => {
        const importOrderData = this.mergeRows(rows);
        this.validateMergedItemQuantities(importOrderData);
        return importOrderData;
      }),
      mergeMap((importOrderData) => {
        return this.validateProductAvailability(importOrderData);
      }),
    );
  }

  private getLoadedCustomerMaterial(): Observable<Record<string, string>> {
    return this.customerMaterialFacade.getLoadedCustomerMaterialRecord().pipe(
      map((record) => {
        const customerMaterialNumberMap: Record<string, string> = {};
        Object.keys(record).forEach((key) => {
          customerMaterialNumberMap[key] = record[key].customerMaterialNumber;
        });
        return customerMaterialNumberMap;
      }),
    );
  }

  splitCartTransferRows(data: string) {
    const itemCount: number = +data.split('-')[0];
    const uomMaterialData = data.split('-')[1];
    const itemList = uomMaterialData.split('+', itemCount);
    const rows: ImportOrderRow[] = [];
    itemList.forEach((item) => {
      const itemUom = item.split('~');
      let caseQty;
      let eachQty;
      const materialNumber = itemUom[0];
      switch (itemUom.length) {
        case 1:
          caseQty = '1';
          eachQty = null;
          break;
        case 2:
          caseQty = itemUom[1];
          eachQty = null;
          break;
        case 3:
          caseQty = itemUom[1];
          eachQty = itemUom[2];
          break;
      }
      rows.push(
        new ImportOrderRow(
          null,
          materialNumber,
          materialNumber,
          caseQty,
          eachQty,
        ),
      );
    });
    return rows;
  }

  private validateFileDataFormat(fileData: string[][]): void {
    if (!this.isValidFile(fileData)) {
      const result = new ValidationResultData(
        ValidationResultType.InvalidFileFormat,
      );
      throw new ImportOrderValidationError(result);
    } else if (this.isEmptyFile(fileData)) {
      const result = new ValidationResultData(ValidationResultType.Empty);
      throw new ImportOrderValidationError(result);
    } else if (!this.hasValidNumberOfColumns(fileData)) {
      const result = new ValidationResultData(
        ValidationResultType.TooFewColumns,
      );
      throw new ImportOrderValidationError(result);
    }
  }

  private validateRowDataFormat(rows: ImportOrderRow[]): void {
    if (rows.length === 0) {
      const result = new ValidationResultData(ValidationResultType.Empty);
      throw new ImportOrderValidationError(result);
    } else if (!this.hasValidNumberOfRows(rows)) {
      const result = new ValidationResultData(ValidationResultType.TooManyRows);
      throw new ImportOrderValidationError(result);
    }

    rows.forEach((row) => {
      this.validateNonEmptyItemId(row);
      this.validateBreakCase(row);
      if (row.error !== ImportOrderRowErrors.WrongBreakCase) {
        this.validateItemQuantities(row);
      }
    });

    const hasValidRow = rows.some((row) => !row.error);
    if (!hasValidRow) {
      const result = new ValidationResultData(
        ValidationResultType.InvalidFileFormat,
      );
      throw new ImportOrderValidationError(result);
    }
  }

  private validateNonEmptyItemId(row: ImportOrderRow): void {
    if (!row.rawId) {
      this.setRowError(row, ImportOrderRowErrors.EmptyId);
    }
  }

  private validateItemQuantities(row: ImportOrderRow): void {
    let error: ImportOrderRowErrors;

    if (isNaN(Number(row.caseQuantity))) {
      error = ImportOrderRowErrors.CasesNotNumeric;
    } else if (isNaN(Number(row.eachQuantity))) {
      error = ImportOrderRowErrors.UnitsNotNumeric;
    } else if (!Number.isInteger(Number(row.caseQuantity))) {
      error = ImportOrderRowErrors.FractionalCase;
    } else if (!Number.isInteger(Number(row.eachQuantity))) {
      error = ImportOrderRowErrors.FractionalUnit;
    } else if (
      Number(row.caseQuantity) > ImportOrderValidationService.MAX_QUANTITY
    ) {
      error = ImportOrderRowErrors.TooManyCases;
    } else if (
      Number(row.eachQuantity) > ImportOrderValidationService.MAX_QUANTITY
    ) {
      error = ImportOrderRowErrors.TooManyUnits;
    } else if (Number(row.caseQuantity) < 0) {
      error = ImportOrderRowErrors.NegativeCase;
    } else if (Number(row.eachQuantity) < 0) {
      error = ImportOrderRowErrors.NegativeUnit;
    }

    this.setRowError(row, error);
  }

  private validateMaterialAccessibility(
    rows: ImportOrderRow[],
  ): Observable<ImportOrderRow[]> {
    const validRows = rows.filter((row) => !row.error);
    const validIds = validRows.map((row) => row.rawId);

    return this.materialValidationService.validateMaterials(validIds).pipe(
      map((materialValidationResults) => {
        validRows.forEach((row) => {
          const rawId = row.rawId;
          if (materialValidationResults.get(rawId)) {
            row.offeringId =
              materialValidationResults.get(rawId).materialNumber;
          } else if (!this.materialNumberRegex.test(rawId)) {
            this.setRowError(row, ImportOrderRowErrors.NotNumeric);
          }
        });
        return rows;
      }),
    );
  }

  private validateMergedItemQuantities(data: ImportOrderData): void {
    data.forEach((rows) => {
      const validRows = rows.filter((row) => !row.error);
      let totalCase = 0;
      let totalUnits = 0;

      validRows.forEach((row) => {
        totalCase = totalCase + Number(row.caseQuantity);
        totalUnits = totalUnits + Number(row.eachQuantity);
      });

      if (totalCase > ImportOrderValidationService.MAX_QUANTITY) {
        validRows.forEach((row) => {
          this.setRowError(row, ImportOrderRowErrors.TooManyMergedCases);
        });
      } else if (totalUnits > ImportOrderValidationService.MAX_QUANTITY) {
        validRows.forEach((row) => {
          this.setRowError(row, ImportOrderRowErrors.TooManyMergedUnits);
        });
      }
    });

    if (this.getImportableIds(data).length === 0) {
      const result = new ValidationResultData(
        ValidationResultType.InvalidFileFormat,
      );
      throw new ImportOrderValidationError(result);
    }
  }

  private validateProductAvailability(
    data: ImportOrderData,
  ): Observable<ImportOrderData> {
    const validIds = this.getImportableIds(data);
    this.materialAvailabilityFacade.loadMaterialAvailabilities(validIds);
    return this.materialAvailabilityFacade
      .getLoadedMaterialAvailabilities(validIds)
      .pipe(
        first(),
        map((availabilities) => {
          const availabilityMap = new Map<string, MaterialAvailability>();
          availabilities
            .filter((availability) => !!availability)
            .forEach((availability) => {
              availabilityMap.set(availability.materialNumber, availability);
            });

          validIds.forEach((id) => {
            const rows = data.get(id);
            const available = availabilityMap.has(id);

            const validUOMs: string[] = [];

            if (available) {
              availabilityMap.get(id).units.forEach((unit) => {
                validUOMs.push(unit.uom);
              });
              data.get(id).at(0).validUoms = validUOMs;
            }

            const unitOrderable =
              available && isAvailableByNonCase(availabilityMap.get(id));
            const caseOrderable =
              available &&
              isAvailableByUom(availabilityMap.get(id), NaooConstants.Uom.Case);
            const orderable = caseOrderable || unitOrderable;

            rows.forEach((row) => {
              let error: ImportOrderRowErrors;
              if (!available) {
                error = ImportOrderRowErrors.NotAvailable;
              } else if (!orderable) {
                error = ImportOrderRowErrors.NoLongerAvailable;
              } else if (
                !unitOrderable &&
                row.eachQuantity !== null &&
                Number(row.eachQuantity) > 0
              ) {
                error = ImportOrderRowErrors.NotUnitOrderable;
              }
              this.setRowError(row, error);
            });
          });
          return data;
        }),
      );
  }

  private isValidFile(fileData: string[][]): boolean {
    return fileData !== undefined && fileData !== null;
  }

  private isEmptyFile(fileData: string[][]): boolean {
    return fileData && fileData.length === 0;
  }

  private hasValidNumberOfColumns(fileData: string[][]): boolean {
    return (
      fileData && fileData.length > 0 && fileData[0] && fileData[0].length >= 2
    );
  }

  private hasValidNumberOfRows(rows: ImportOrderRow[]): boolean {
    return rows.length <= ImportOrderValidationService.MAX_LINES;
  }

  private hasHeader(fileData: string[][]): boolean {
    return isNaN(Number(fileData[0][0])) && isNaN(Number(fileData[0][1]));
  }

  private createRows(
    fileData: string[][],
    customerMaterial: Record<string, string>,
  ): ImportOrderRow[] {
    const hasHeader = this.hasHeader(fileData);
    if (hasHeader) {
      fileData.splice(0, 1);
    }
    return fileData
      .map((row, index) => {
        const rawId = row[RowData.OfferingId];
        const offeringId = rawId;
        const breakCase = this.isBreakCaseFormat(row)
          ? row[RowData.UnitQuantity]
          : null;
        const caseQty = this.calculateCaseQty(breakCase, row);
        const unitQty = this.calculateUnitQty(breakCase, row);
        const rowNumber = hasHeader ? index + 2 : index + 1;
        return new ImportOrderRow(
          rowNumber,
          rawId,
          offeringId,
          caseQty,
          unitQty,
          breakCase,
          customerMaterial[offeringId],
        );
      })
      .filter((row) => !this.isBlankRow(row));
  }

  private calculateUnitQty(breakCase: string, row: string[]): string {
    let unitQty = null;
    if (breakCase) {
      if (breakCase.toUpperCase() === BreakCase.NO) {
        unitQty = '0';
      } else {
        unitQty = row[RowData.CaseQuantity];
      }
    } else if (row.length > 2) {
      unitQty = row[RowData.UnitQuantity];
    }

    return unitQty;
  }

  private calculateCaseQty(breakCase: string, row: string[]): string {
    return this.canSetCaseQuantity(breakCase, row)
      ? row[RowData.CaseQuantity]
      : null;
  }

  private canSetCaseQuantity(breakCase: string, row: string[]): boolean {
    return (
      (row.length > 2 && this.didNotBreakCase(breakCase, row)) ||
      row.length <= 2
    );
  }

  private didNotBreakCase(breakCase: string, row: string[]): boolean {
    return (
      !isNaN(Number(row[RowData.UnitQuantity])) ||
      !breakCase ||
      breakCase.toUpperCase() === BreakCase.NO
    );
  }

  private isBreakCaseFormat(row: string[]): boolean {
    const value = row[RowData.UnitQuantity];
    const isString = isNaN(Number(value));
    return (
      value && isString && validBreakCaseValues.includes(value.toUpperCase())
    );
  }

  private mergeRows(rows: ImportOrderRow[]): ImportOrderData {
    const result = new Map<string, ImportOrderRow[]>();
    rows.forEach((row) => {
      if (result.has(row.offeringId)) {
        result.get(row.offeringId).push(row);
      } else {
        result.set(row.offeringId, [row]);
      }
    });
    return result;
  }

  private createValidationResult(data: ImportOrderData): ValidationResultData {
    const validationResult = new ValidationResultData();
    validationResult.data = data;

    if (this.getImportableIds(data).length === 0) {
      validationResult.type = ValidationResultType.InvalidFileFormat;
      throw new ImportOrderValidationError(validationResult);
    } else if (this.hasRowErrors(validationResult)) {
      validationResult.type = ValidationResultType.RowErrors;
      throw new ImportOrderValidationError(validationResult);
    } else {
      validationResult.type = ValidationResultType.NoErrors;
    }
    return validationResult;
  }

  private isBlankRow(row: ImportOrderRow): boolean {
    const isBlank = (str: string) =>
      str === undefined ||
      str === null ||
      str.trim().length === 0 ||
      str.trim() === '0';
    return isBlank(row.caseQuantity) && isBlank(row.eachQuantity);
  }

  private setRowError(row: ImportOrderRow, error: ImportOrderRowErrors): void {
    if (!row.error && error) {
      row.error = error;
    }
  }

  private getImportableIds(data: ImportOrderData): string[] {
    const validIds: string[] = [];
    data.forEach((rows, offeringId) => {
      if (
        rows.some(
          (row) =>
            !row.error || importOrderImportableErrors.includes(row.error),
        )
      ) {
        validIds.push(offeringId);
      }
    });
    return validIds;
  }

  private hasRowErrors(validationResults: ValidationResultData): boolean {
    let hasError = false;
    validationResults.data.forEach((rows) => {
      hasError = hasError || rows.some((row) => row.error !== undefined);
    });
    return hasError;
  }

  private validateBreakCase(row: ImportOrderRow) {
    if (row.breakCase === null && isNaN(Number(row.eachQuantity))) {
      row.error = ImportOrderRowErrors.WrongBreakCase;
    }
  }
}
