import { first, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { OrderInfo } from '../../../shared/models/order-info';
import { OrderService } from '../../../shared/services/order/order.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterExtrasService } from '../../../shared/services/router-extras/router-extras.service';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { CONTENT_SCROLL } from '../../../shared/services/scrollable-content/scrollable-content.service';
import { OrderHistoryTransformationService } from './order-history-transformation.service';
import { OrderHistoryViewModel } from './order-history-view-model';
import { SearchFieldComponent } from '../../../shared/search-bar/search-field.component';
import { DeviceIdentifierService } from '../../../shared/services/device-identifier/device-identifier.service';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { VirtualScrollerComponent } from '../../../vendor/ngx-virtual-scroller/virtual-scroller';

@Component({
  selector: 'naoo-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderHistoryComponent implements OnInit, OnDestroy {
  static readonly ANALYTICS_CATEGORY = 'order history summary';
  static readonly ANALYTICS_ACTION_DISPLAYED = 'displayed';
  static readonly ANALYTICS_LABEL_SERVICE_ERROR =
    'open+invoiced order service error';
  static readonly ANALYTICS_LABEL_NO_ORDERS = 'no order data';
  static readonly ANALYTICS_LABEL_VIEW_DETAILS = 'view details';

  readonly SEARCH_QUERY = 'filter';

  orders: OrderInfo[];
  orderViewModels: OrderHistoryViewModel[] = [];

  destroyed$ = new Subject();
  locationPath: string;
  hasOrderHistoryError = false;
  isMobile = false;
  searchTerm = '';

  filteredOrderViewModels: OrderHistoryViewModel[] = [];

  @ViewChild('scroll')
  virtualScroll: VirtualScrollerComponent<OrderHistoryViewModel>;

  @ViewChild('searchField', {
    static: true,
  })
  searchField: SearchFieldComponent;
  // eslint-disable-next-line max-params
  constructor(
    private orderService: OrderService,
    private localizationService: LocalizationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerExtrasService: RouterExtrasService,
    private analytics: NaooAnalyticsManager,
    private transformationService: OrderHistoryTransformationService,
    private changeDetector: ChangeDetectorRef,
    private deviceIdentifierService: DeviceIdentifierService,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  ngOnInit() {
    this.deviceIdentifierService
      .observeDeviceType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.locationPath = window.location.href;
    this.loadOrderHistory();

    this.localizationService
      .locale()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.orders) {
          this.orderViewModels = this.orders
            .map((order) => this.transformationService.transform(order))
            .sort(this.compareOrders);
        }
        this.filterOrders(this.searchTerm);
      });

    this.activatedRoute.params
      .pipe(takeUntil(this.destroyed$))
      .subscribe((params) => {
        this.searchTerm = params[this.SEARCH_QUERY]
          ? params[this.SEARCH_QUERY]
          : '';
        this.filterOrders(this.searchTerm);
      });
  }

  ngOnDestroy() {
    if (this.virtualScroll) {
      this.routerExtrasService.setScrollToElementIndex(
        this.virtualScroll.viewPortInfo.startIndex,
      );
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  orderStatusClick(event: Event) {
    event.stopPropagation();
  }

  goToOrderDetails(routeParams: unknown[]) {
    this.trackOrderHistoryAnalytics(
      OrderHistoryComponent.ANALYTICS_LABEL_VIEW_DETAILS,
    );

    this.router.navigate(routeParams, {
      relativeTo: this.activatedRoute,
    });
  }

  goToCategories() {
    this.router.navigate(['categories']);
  }

  goToGuides() {
    this.router.navigate(['guides']);
  }

  trackByOrderNumber(index: number, viewModel: OrderHistoryViewModel): string {
    return viewModel.orderNumber;
  }

  compareViewModels(
    orderHistory: OrderHistoryViewModel,
    otherOrderHistory: OrderHistoryViewModel,
  ): boolean {
    return !!(
      orderHistory &&
      otherOrderHistory &&
      orderHistory.orderNumber === otherOrderHistory.orderNumber
    );
  }

  private loadOrderHistory() {
    this.orderService
      .getOrders()
      .pipe(first())
      .subscribe(
        (orderResponse) => {
          this.orders = orderResponse.orders;
          this.orderViewModels = orderResponse.orders
            .map((order) => this.transformationService.transform(order))
            .sort(this.compareOrders);
          this.hasOrderHistoryError = false;
          this.filteredOrderViewModels = this.orderViewModels;

          setTimeout(() => {
            if (
              this.virtualScroll &&
              this.routerExtrasService.getRestoreScrollPosition(
                this.locationPath,
              )
            ) {
              this.routerExtrasService.setRestoreScrollPosition(
                this.locationPath,
                false,
              );
              this.virtualScroll.scrollToIndex(
                this.routerExtrasService.getScrollToElementIndex(),
              );
            }
          });

          if (orderResponse && orderResponse.orders.length === 0) {
            this.trackOrderHistoryAnalytics(
              OrderHistoryComponent.ANALYTICS_LABEL_NO_ORDERS,
            );
          }
          this.filterOrders(this.searchTerm);
        },
        () => {
          this.orders = null;
          this.hasOrderHistoryError = true;
          this.trackOrderHistoryAnalytics(
            OrderHistoryComponent.ANALYTICS_LABEL_SERVICE_ERROR,
          );
          this.changeDetector.markForCheck();
        },
      );
  }

  private trackOrderHistoryAnalytics(eventLabel: string) {
    this.analytics.trackAnalyticsEvent({
      action: OrderHistoryComponent.ANALYTICS_ACTION_DISPLAYED,
      category: OrderHistoryComponent.ANALYTICS_CATEGORY,
      label: eventLabel,
    });
  }

  filterOrders(value: string) {
    this.searchTerm = value;
    const filterByString = value ? value.trim().toLocaleLowerCase() : '';
    if (filterByString.length) {
      this.filteredOrderViewModels = this.orderViewModels.filter((order) =>
        order.searchableStrings.some((field) => field.includes(filterByString)),
      );
    } else {
      this.filteredOrderViewModels = this.orderViewModels;
    }
    this.changeDetector.markForCheck();
  }

  updateSearchParam(value: string) {
    let matrixParams;
    if (value === '') {
      this.searchTerm = '';
      matrixParams = [{}];
    } else {
      matrixParams = [{ filter: this.searchTerm }];
    }
    this.router.navigate(matrixParams, {
      queryParamsHandling: 'preserve',
    });
  }

  private compareOrders(
    order1: OrderHistoryViewModel,
    order2: OrderHistoryViewModel,
  ): number {
    const dateDifference =
      new Date(order2.calculatedDate).getTime() -
      new Date(order1.calculatedDate).getTime();
    if (!dateDifference) {
      // Secondary sort
      return order2.orderNumber > order1.orderNumber ? 1 : -1;
    }
    return dateDifference;
  }
}
