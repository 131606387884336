import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { Router } from '@angular/router';
import {
  ImportOrderValidationService,
  ValidationResultData,
  ValidationResultType,
} from '../../shared/services/import-order-validation/import-order-validation.service';
import { CartLine, CartMaterial } from '../../shared/models/cart-material';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { DefaultDialogService } from '../../shared/services/dialog/default-dialog/default-dialog.service';
import {
  importOrderImportableErrors,
  ImportOrderRow,
} from '../../shared/models/import-order-row';
import { CartFacade } from 'src/app/core/store/cart/cart.facade';
import { CustomerMaterialFacade } from '../../core/store/customer-material/customer-material.facade';
import { MaterialInfoFacade } from '../../core/store/material-info/material-info.facade';
import { NaooConstants } from '../../shared/NaooConstants';
import { MaterialAvailabilityFacade } from '../../core/store/material-availability/material-availability.facade';
import { ImportOrderModalAnalytics } from './shared/import-order-modal-analytics';
import { ImportOrderStep } from './shared/import-order-modal-step';
import { CartPriceTotals } from '../../core/store/material-price/material-price.selectors';
import { ToastMessageService } from '../../shared/services/toast-message/toast-message.service';
import { NaooLocalStorage } from '../../shared/storage/local-storage/local-storage.service';
import { MaterialRowContext } from '../../core/store/material-row/models/material-row';
import { CartActionType } from '../../shared/bulk-add-modal/bulk-add-modal.component';
import { mergeMaterialList } from '../../shared/utilities/cart-material-utilities';

@Component({
  selector: 'naoo-import-order-modal',
  templateUrl: './import-order-modal.component.html',
  styleUrls: ['./import-order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportOrderModalComponent implements OnInit, OnDestroy {
  private readonly fileTypeRequirement =
    'IMPORT_ORDER.IMPORT_FILE.FILE_TYPE_REQUIREMENT';
  private readonly columnFormatRequirement =
    'IMPORT_ORDER.IMPORT_FILE.COLUMN_FORMAT_REQUIREMENT';
  private readonly maxLinesRequirement =
    'IMPORT_ORDER.IMPORT_FILE.MAX_LINES_REQUIREMENT';

  private readonly tooManyRowsError =
    'IMPORT_ORDER.FILE_ERROR.TOO_MANY_ROWS_ERROR';
  private readonly tooFewColumnsError =
    'IMPORT_ORDER.FILE_ERROR.TOO_FEW_COLUMNS_ERROR';
  private readonly invalidFormatError =
    'IMPORT_ORDER.FILE_ERROR.INVALID_FORMAT_ERROR';

  private readonly validationServiceFailureTag =
    'validating input file for import order';

  importOrderCurrentStep: ImportOrderStep | null;
  destroyed$ = new Subject();
  cartPriceTotals: CartPriceTotals;
  nonDeletedMaterials: CartMaterial[];
  showCloseButton: boolean;
  fileName: string;
  fileRequirements: string[];
  fileError: string;
  validationResultData: ValidationResultData;
  importableRowCount: number;
  totalRowCount: number;
  invalidImportOrderRows: ImportOrderRow[];
  customerMaterialEnabled: boolean;
  cartTransferData: string;
  private includesInvalidRows = false;
  private importableRowsOfferingIds: string[];

  get importOrderStep(): typeof ImportOrderStep {
    return ImportOrderStep;
  }

  // eslint-disable-next-line max-params
  constructor(
    private dialogRef: MatDialogRef<ImportOrderModalComponent>,
    private cartFacade: CartFacade,
    private materialAvailabilityFacade: MaterialAvailabilityFacade,
    private materialInfoFacade: MaterialInfoFacade,
    private customerMaterialFacade: CustomerMaterialFacade,
    private router: Router,
    private defaultDialogService: DefaultDialogService,
    private importOrderValidationService: ImportOrderValidationService,
    private importOrderAnalytics: ImportOrderModalAnalytics,
    private changeDetectorRef: ChangeDetectorRef,
    private naooLocalStorage: NaooLocalStorage,
    private toastMessageService: ToastMessageService,
    @Inject(MAT_DIALOG_DATA) public data: CartActionType,
  ) {}

  ngOnInit() {
    this.fileRequirements = [
      this.fileTypeRequirement,
      this.columnFormatRequirement,
      this.maxLinesRequirement,
    ];
    this.showCloseButton = true;

    this.cartFacade
      .getLoadedCart()
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe((cart) => {
        this.nonDeletedMaterials = cart.materials.filter(
          (material) => !material.deleted,
        );

        this.setModalState(ImportOrderStep.ImportFile);
      });

    this.cartFacade
      .getCartPriceTotals()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((cartPriceTotals: CartPriceTotals) => {
        this.cartPriceTotals = cartPriceTotals;
        this.changeDetectorRef.markForCheck();
      });

    if (this.naooLocalStorage.getItem(NaooConstants.CART_TRANSFER_CONTENT)) {
      this.cartTransferData = this.naooLocalStorage.getItem(
        NaooConstants.CART_TRANSFER_CONTENT,
      );
    }

    if (this.cartTransferData) {
      this.setModalState(ImportOrderStep.Loading);
      this.importContentStepNext(null);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  closeModal(userCanceled: boolean) {
    this.dialogRef.close();
    if (userCanceled) {
      this.importOrderAnalytics.trackCloseModal(this.importOrderCurrentStep);
    }
  }

  goToCart() {
    this.router.navigate([NaooConstants.CART_PATH]);
    this.closeModal(false);
  }

  importContentStepNext(file: Blob) {
    if (file) {
      this.fileName = (file as File).name;
      this.importOrderAnalytics.trackSelectedFileType(this.fileName);
      this.setModalState(ImportOrderStep.Loading);
    }
    this.importOrderValidationService
      .validateContent(file, this.cartTransferData)
      .pipe(first())
      .subscribe(
        (result) => {
          this.handleValidationResult(result);
        },
        () => {
          this.importOrderError(this.validationServiceFailureTag);
        },
      );
  }

  private handleValidationResult(result: ValidationResultData) {
    this.validationResultData = result;
    const nextStep = this.determineStatePostValidation(result.type);
    if (this.cartTransferData && nextStep != ImportOrderStep.RowError) {
      this.closeModal(false);
    }
    this.segregateImportRows(result.type);
    this.setModalState(nextStep);
    this.importOrderAnalytics.trackValidationResult(nextStep);
    if (result.type === ValidationResultType.Empty) {
      this.goToCart();
      this.toastMessageService.showCartImportToastMessage(0);
      if (this.cartTransferData) {
        this.naooLocalStorage.removeItem(NaooConstants.CART_TRANSFER_CONTENT);
      }
    } else if (result.type === ValidationResultType.NoErrors) {
      this.importOrder();
      if (this.cartTransferData) {
        this.naooLocalStorage.removeItem(NaooConstants.CART_TRANSFER_CONTENT);
      }
    }
  }

  startOver() {
    if (this.cartTransferData) {
      this.naooLocalStorage.removeItem(NaooConstants.CART_TRANSFER_CONTENT);
      this.goToCart();
    }
    this.setModalState(ImportOrderStep.ImportFile);
  }

  rowErrorStepNext() {
    this.setModalState(ImportOrderStep.Loading);
    this.importOrder();
    this.importOrderAnalytics.trackImportWithErrors(this.includesInvalidRows);
  }

  includeInvalidRows(includeInvalid: boolean) {
    this.includesInvalidRows = includeInvalid;
    this.segregateImportRows(this.validationResultData.type);
  }

  importOrderError(value: string) {
    this.closeModal(false);
    this.dialogRef.afterClosed().subscribe(() => {
      this.displayDefaultErrorModal(value);
    });
  }

  trackTemplateDownload(type: string) {
    this.importOrderAnalytics.trackTemplateDownload(
      this.importOrderCurrentStep,
      type,
    );
  }

  private importOrder() {
    const combinedCartQuantities = mergeMaterialList(
      this.getCartMaterialsToImport(),
      this.data === CartActionType.KEEP ? this.nonDeletedMaterials : [],
      MaterialRowContext.ImportOrder,
    );
    const materialIds = combinedCartQuantities.map(
      (item) => item.materialNumber,
    );

    this.cartFacade.updateCartQuantities(combinedCartQuantities);
    this.materialAvailabilityFacade.loadMaterialAvailabilities(materialIds);
    this.materialInfoFacade.loadMaterialInfos(materialIds);

    const cartDoneUpdating$ = this.cartFacade.isDoneUpdating().pipe(
      filter((isDoneUpdating) => isDoneUpdating),
      first(),
      tap((_) => this.setModalState(ImportOrderStep.Complete)),
    );

    const cartPriceTotals$ = this.cartFacade
      .getCartPriceTotals()
      .pipe(filter((cartPriceTotals) => !!cartPriceTotals.hasAllPricesLoaded));

    combineLatest([cartDoneUpdating$, cartPriceTotals$])
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe(([_, cartPriceTotals]) => {
        this.importOrderAnalytics.trackImportComplete(
          cartPriceTotals.estimatedCost,
        );
      });

    this.goToCart();
    this.toastMessageService.showCartImportToastMessage(
      this.importableRowCount,
    );
    if (this.cartTransferData) {
      this.naooLocalStorage.removeItem(NaooConstants.CART_TRANSFER_CONTENT);
    }
  }

  private displayDefaultErrorModal(value: string) {
    this.defaultDialogService.defaultErrorModal(value);
  }

  private determineStatePostValidation(
    validationResultType: ValidationResultType,
  ): ImportOrderStep | null {
    switch (validationResultType) {
      case ValidationResultType.InvalidFileFormat:
        this.fileError = this.invalidFormatError;
        return ImportOrderStep.FileError;
      case ValidationResultType.TooManyRows:
        this.fileError = this.tooManyRowsError;
        return ImportOrderStep.FileError;
      case ValidationResultType.TooFewColumns:
        this.fileError = this.tooFewColumnsError;
        return ImportOrderStep.FileError;
      case ValidationResultType.RowErrors:
        return ImportOrderStep.RowError;
      case ValidationResultType.NoErrors:
        return ImportOrderStep.Loading;
      default:
        return null;
    }
  }

  private setModalState(importOrderStep: ImportOrderStep | null) {
    this.showCloseButton = importOrderStep !== ImportOrderStep.Loading;
    if (importOrderStep === ImportOrderStep.RowError) {
      this.customerMaterialFacade
        .hasCustomerMaterial()
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe((customerMaterialEnabled) => {
          this.customerMaterialEnabled = customerMaterialEnabled;
        });
    }

    this.importOrderCurrentStep = importOrderStep;
    this.changeDetectorRef.markForCheck();
  }

  private getCartMaterialsToImport(): CartMaterial[] {
    return this.importableRowsOfferingIds.map((id) => {
      const uomValues = new Map<string, number>();

      this.validationResultData.data.get(id).forEach((row) => {
        const uomAvailable =
          Array.isArray(row.validUoms) && !!row.validUoms.length;

        if (uomAvailable) {
          row.validUoms.forEach((uomName) => {
            uomValues.set(uomName, 0);
          });
        } else {
          uomValues.set(NaooConstants.Uom.Case, 0);
          uomValues.set(NaooConstants.Uom.Unit, 0);
        }

        if (row.error === undefined || this.canImportInvalidRow(row)) {
          if (uomAvailable) {
            row.validUoms.forEach((uom) => {
              const quantity =
                NaooConstants.Uom.Case === uom
                  ? Number(row.caseQuantity)
                  : Number(row.eachQuantity);
              uomValues.set(uom, uomValues.get(uom) + Number(quantity));
            });
          } else {
            uomValues.set(
              NaooConstants.Uom.Case,
              uomValues.get(NaooConstants.Uom.Case) + Number(row.caseQuantity),
            );
            uomValues.set(
              NaooConstants.Uom.Unit,
              uomValues.get(NaooConstants.Uom.Unit) + Number(row.eachQuantity),
            );
          }
        }
      });
      const unitsAndQuantity: CartLine[] = [];
      uomValues.forEach((value, key) => {
        unitsAndQuantity.push({ quantity: value, uom: key });
      });
      return new CartMaterial(id, unitsAndQuantity);
    });
  }

  private segregateImportRows(type: ValidationResultType) {
    if (
      type !== ValidationResultType.Empty &&
      type !== ValidationResultType.RowErrors &&
      type !== ValidationResultType.NoErrors
    ) {
      return;
    }
    this.invalidImportOrderRows = [];
    this.importableRowsOfferingIds = [];
    this.importableRowCount = 0;
    this.totalRowCount = 0;
    this.validationResultData.data.forEach((importOrderRows) => {
      importOrderRows.forEach((importOrderRow) => {
        this.totalRowCount++;
        if (importOrderRow.error === undefined) {
          this.addImportableRowId(importOrderRow.offeringId);
          this.importableRowCount++;
        } else if (this.canImportInvalidRow(importOrderRow)) {
          this.addImportableRowId(importOrderRow.offeringId);
          this.importableRowCount++;
          this.invalidImportOrderRows.push(importOrderRow);
        } else {
          this.invalidImportOrderRows.push(importOrderRow);
        }
      });
    });
    this.sortImportOrderRows();
  }

  private canImportInvalidRow(importOrderRow: ImportOrderRow) {
    return (
      this.includesInvalidRows &&
      importOrderImportableErrors.includes(importOrderRow.error)
    );
  }

  private sortImportOrderRows() {
    this.invalidImportOrderRows.sort((row1, row2) => {
      return row1.rowNumber - row2.rowNumber;
    });
  }

  private addImportableRowId(offeringId: string) {
    if (!this.importableRowsOfferingIds.includes(offeringId)) {
      this.importableRowsOfferingIds.push(offeringId);
    }
  }
}
