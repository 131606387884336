import {
  MaterialAdditionalInfoRecordState,
  MaterialAdditionalInfoRecordStatus,
} from './material-additional-info.state';
import {
  MaterialAdditionalInfoRecord,
  MaterialAttributeRecord,
} from '../../services/material-additional-info/models/material-additional-infos-record';
import {
  MaterialAdditionalInfo,
  MaterialAttribute,
} from '../../../shared/models/material-additional-info';
import { Localized } from '../../../shared/models/localized';

export function hasMaterialAdditionalInfoFinishedLoading(
  recordState: MaterialAdditionalInfoRecordState,
): boolean {
  return (
    !!recordState &&
    [
      MaterialAdditionalInfoRecordStatus.Error,
      MaterialAdditionalInfoRecordStatus.Success,
    ].includes(recordState.status)
  );
}

export function buildMaterialAdditionalInfo(
  additionalInfoRecord: MaterialAdditionalInfoRecord,
): MaterialAdditionalInfo {
  return additionalInfoRecord
    ? {
        materialNumber: additionalInfoRecord.materialNumber,
        sellingParagraphs: setLegacyKeys(
          additionalInfoRecord.sellingParagraphs,
        ),
        sellingBulletPoints: setLegacyKeys(
          additionalInfoRecord.sellingBulletPoints,
        ),
        servingSuggestions: setLegacyKeys(
          additionalInfoRecord.servingSuggestions,
        ),
        preparationSuggestions: setLegacyKeys(
          additionalInfoRecord.preparationSuggestions,
        ),
        packagingStorage: setLegacyKeys(additionalInfoRecord.packagingStorage),
        ingredients: setLegacyKeys(additionalInfoRecord.ingredients),
        forMoreInformation: setLegacyKeys(
          additionalInfoRecord.forMoreInformation,
        ),
        description: setLegacyKeys(additionalInfoRecord.description),
        materialAttributes: additionalInfoRecord.materialAttributes?.map(
          (materialAttribute) => buildMaterialAttribute(materialAttribute),
        ),
        nutritionalInfo: { ...additionalInfoRecord.nutritionalInfo },
        allergenInfo: { ...additionalInfoRecord.allergenInfo },
        halalInfo: additionalInfoRecord.halalInfo,
        images: setLegacyKeys(additionalInfoRecord.images),
        volumeMl: additionalInfoRecord.volumeMl,
        portionQty: additionalInfoRecord.portionQty,
        portionUom: additionalInfoRecord.portionUom,
        categoryLevel1: setLegacyKeys(additionalInfoRecord.categoryLevel1),
        materialDimensions: { ...additionalInfoRecord.materialDimensions },
        vendorInfo: { ...additionalInfoRecord.vendorInfo },
        temperatureConditions: additionalInfoRecord.temperatureConditions,
        materialYield: setLegacyKeys(additionalInfoRecord.materialYield),
        marketingTips: setLegacyKeys(additionalInfoRecord.marketingTips),
        thawInstructions: setLegacyKeys(additionalInfoRecord.thawInstructions),
        shelfLife: setLegacyKeys(additionalInfoRecord.shelfLife),
      }
    : undefined;
}

function buildMaterialAttribute(
  materialAttribute: MaterialAttributeRecord,
): MaterialAttribute {
  return {
    name: setLegacyKeys(materialAttribute.name),
    value: materialAttribute.value,
  };
}

function setLegacyKeys<T>(localized: Localized<T>): Localized<T> {
  return localized
    ? {
        en: localized.en ? localized.en : undefined,
        fr: localized.fr ? localized.fr : undefined,
        es: localized.es ? localized.es : undefined,
      }
    : {
        en: null,
        fr: null,
        es: null,
      };
}
