import { Localized } from '../../models/localized';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MaterialInfoFacade } from 'src/app/core/store/material-info/material-info.facade';
import { Dictionary } from '@ngrx/entity';
import { MaterialInfoRecordState } from 'src/app/core/store/material-info/material-info.state';
import { NaooConstants } from '../../NaooConstants';
import { Language } from '../../../core/services/session/models/session-record';

export interface MaterialImageCache {
  [materialNumber: string]: DimensionedImageState;
}

export interface DimensionedImageState {
  [dimension: number]: Localized<MaterialImageSources>;
}

export interface MaterialImageSources {
  src: string;
  srcSet: string;
  srcMap: Map<number, string>;
}

@Injectable({ providedIn: 'root' })
export class MaterialImageSourcesService {
  private readonly placeholderSources: MaterialImageSources = {
    src: NaooConstants.placeHolderImagePath,
    srcSet: NaooConstants.placeHolderImagePath,
    srcMap: undefined,
  };

  private readonly localizedPlaceholderSources: Localized<MaterialImageSources> =
    {
      en: this.placeholderSources,
      fr: this.placeholderSources,
      es: this.placeholderSources,
    };

  private materialImageCache: MaterialImageCache = {};
  private materialInfoRecords: Dictionary<MaterialInfoRecordState>;

  constructor(private readonly materialInfoFacade: MaterialInfoFacade) {
    this.materialInfoFacade
      .getAllMaterialInfos()
      .subscribe((materialInfos) => (this.materialInfoRecords = materialInfos));
  }

  getImageSources(
    materialNumber: string,
    dimension: number,
  ): Localized<MaterialImageSources> {
    if (this.materialImageCache?.[materialNumber]?.[dimension]) {
      return this.materialImageCache[materialNumber][dimension];
    }
    const image = this.materialInfoRecords[materialNumber]?.record?.image;

    if (dimension && image) {
      const sources: Localized<MaterialImageSources> = {
        en: undefined,
        fr: undefined,
        es: undefined,
      };
      Object.keys(image).forEach((lang: Language) => {
        const url: string | undefined = image[lang]?.url;

        sources[lang] = this.createImageUrls(url, dimension);
      });

      this.materialImageCache[materialNumber] = {
        ...this.materialImageCache?.[materialNumber],
        [dimension]: this.completeMissingImageSources(sources),
      };
      return this.materialImageCache[materialNumber][dimension];
    }

    return this.localizedPlaceholderSources;
  }

  createImageUrls(url: string | null, dimension: number): MaterialImageSources {
    if (!url) {
      return this.placeholderSources;
    }

    const urlWithParameters = this.addParamsToImageUrl(url, dimension, 1);
    const src = urlWithParameters ? urlWithParameters : url;
    const srcMap = this.createSrcMap(url, dimension, [1, 2, 3]);
    const srcSet = [...srcMap.entries()]
      .map((entry) => `${entry[1]} ${entry[0]}x`)
      .join(', ');

    return {
      src,
      srcSet,
      srcMap,
    };
  }
  private createSrcMap(
    imageUrl: string,
    dimension: number,
    densities: number[],
  ): Map<number, string> {
    const srcMap = new Map<number, string>();
    densities.forEach((density) => {
      const url = this.addParamsToImageUrl(imageUrl, dimension, density);
      if (url) {
        srcMap.set(density, url);
      }
    });
    return srcMap;
  }

  private completeMissingImageSources(
    sources: Localized<MaterialImageSources>,
  ): Localized<MaterialImageSources> {
    if (sources.en?.src === this.placeholderSources.src) {
      sources.en = sources.fr;
    } else if (sources.fr?.src === this.placeholderSources.src) {
      sources.fr = sources.en;
    } else if (sources.es?.src === this.placeholderSources.src) {
      sources.es = sources.en;
    }
    return sources;
  }

  private addParamsToImageUrl(
    imageUrl: string,
    dimension: number,
    canvasMultiplier: number,
  ): string | undefined {
    if (!imageUrl || imageUrl.length === 0 || dimension <= 0) {
      return undefined;
    }

    let params = new HttpParams();
    params = params.append('width', `${dimension}`);
    params = params.append('height', `${dimension}`);
    params = params.append('auto', 'webp');
    params = params.append('fit', 'bounds');
    params = params.append(
      'canvas',
      `${dimension * canvasMultiplier},${dimension * canvasMultiplier}`,
    );
    params = params.append('dpr', `${canvasMultiplier}`);

    return `${imageUrl}?${params}`;
  }
}
